import syncChartsHelper from 'helpers/synchronized-highcharts';
import fwHighchartsDefaults from 'helpers/highcharts';
fwHighchartsDefaults.init();


//Dynamically sets minimum and maximum for the chart, so that the first and last points go to the edge
const setPointMinMax = (chart) => {
	// eslint-disable-next-line no-inline-comments
	import(/* webpackChunkName: "highcharts" */ 'highcharts').then((Highcharts) => {
		const thisChart = chart;
		const chartData = thisChart.options.series[0].data;

		thisChart.xAxis[0].update({
			min: chartData[0][0]
		});

		thisChart.xAxis[0].update({
			max: chartData[chartData.length - 1][0]
		});

		//Animation is off by default to prevent the chart from jerking on render; this gives it animation once it's interactive
		thisChart.options.chart.animation = { duration: 50 };

	});
};

//The main function that renders all the charts
const synchronizedHighchartsSetup = (data = []) => {
	// eslint-disable-next-line no-inline-comments
	import(/* webpackChunkName: "highcharts" */ 'highcharts').then((Highcharts) => {

		//Several helper functions required to run synchronized charts
		//Abstracted for a cleaner file; must pass Highcharts as an argument
		syncChartsHelper.init(Highcharts);

		data.forEach((dataset, index) => {

			if (dataset.data && dataset.id) {

				//Strip any "#" that may have been supplied with the ID
				const ID = dataset.id.charAt(0) === '#' ? dataset.id.substr(1) : dataset.id;
				const syncChartEl = document.getElementById(ID);

				if (syncChartEl) {
					//Render the chart with all the below options to the ID specified
					const chart = Highcharts.chart(syncChartEl, {
						chart: {
							type: 'area',
							plotBorderWidth: 1,
							plotBorderColor: '#ebebeb',
							animation: false,
							height: '250px'
						},
						title: {
							text: dataset.title || '',
							align: 'left',
							margin: 10,
							style: {
								fontSize: '2rem'
							},
							x: -10,
							y: 0
						},
						plotOptions: {
							series: {
								line: {
									zIndex: -1
								},
								fillOpacity: 0.3,
								marker: {
									enabled: true,
									states: {
										hover: {
											radiusPlus: 4,
											fillColor: '#ffffff',
											lineColor: '#01516E',
											lineWidth: 6
										}
									}
								}
							}
						},
						yAxis: {
							endOnTick: true,
							startOnTick: true
						},
						xAxis: {
							crosshair: {
								snap: true,
								dashStyle: 'dash',
								width: 2,
								color: '#01516E',
								events: {
									setExtremes: fwHighchartsDefaults.syncExtremes
								},
								labels: {
									format: '{value}'
								}
							},
							startOnTick: false,
							endOnTick: false,
							type: 'datetime',
							labels: {
								step: 1,
								format: '{value:%e %b}',
								style: {
									//Styled thusly to override the default
									'font-size': '1.4rem',
									color: '#5D5E5E'
								}
							}
						},
						tooltip: {
							enabled: true,
							positioner () {
								return {
									x: this.chart.chartWidth - this.label.width,
									y: -50
								};
							},
							borderWidth: 0,
							backgroundColor: 'none',
							pointFormat: '{point.y}',
							headerFormat: '',
							shadow: false,
							style: {
								fontSize: '2rem',
								color: '#262727',
								fontWeight: '500'
							},
							valueDecimals: dataset.decimals || 0,
							valueSuffix: dataset.suffix || ''
						},
						series: [{
							data: dataset.data
						}]
					});
					setPointMinMax(chart);
				} else {
					// eslint-disable-next-line no-console
					console.warn('Synchronized charts could not be rendered. Be sure an array of data was provided, and that each array item has an ID property matching an existing DOM element.');
				}
			}
		});
	});
};

export default synchronizedHighchartsSetup;
