import fwHighchartsDefaults from 'helpers/highcharts';
fwHighchartsDefaults.init();

const renderPieChart = (data = [], target = '', countType = 'file') => {
	// eslint-disable-next-line no-inline-comments
	import(/* webpackChunkName: "highcharts" */ 'highcharts').then((Highcharts) => {
		//We need to do some setup and transformation on the initial data array; this empty array will be populated and used to generate the chart
		const formattedData = [];
		//Where the chart's gonna go in the DOM
		const pieChartContainer = document.querySelector(target);
		//Degrees rotation to start the chart
		let start = 0;

		//Lets us set Flywheel brand colors for each slice
		const defaultColors = [
			{
				color: '#f47820',
				altColor: '#8e4402'
			},
			{
				color: '#b0e0ea',
				altColor: '#04516e'
			},
			{
				color: '#51bb7b',
				altColor: '#257048'
			},
			{
				color: '#8350a0',
				altColor: '#4d275f'
			},
			{
				color: '#ef4e65',
				altColor: '#8c2738'
			},
			{
				color: '#f0ce15',
				altColor: '#9a7700'
			},
			{
				color: '#e0368c',
				altColor: '#851252'
			},
			{
				color: '#9f9c9c',
				altColor: '#434344'
			}
		];

		//In order to place some labels inside the chart and some outside, each "slice" actually needs to be its own data set in the series. This puts things in the needed format.
		data.forEach((slice, index) => {
			//Only give this slice a label if its value is 20 or higher
			let label = false;
			if (slice.y >= 20) {
				label = {
					//Places label inside pie slice if value is 15+; otherwise, places label further away from pie slice the smaller the value.
					distance: -45,
					style: {
						//Makes label white if inside the pie chart, or the darker version of the slice if outside
						color: '#fff'
					}
				};
			}

			//Set default colors for each slice
			slice.color = slice.color || defaultColors[index].color;
			slice.altColor = slice.altColor || defaultColors[index].altColor;

			//Set the rotation for this pie slice
			const end = start + (360 * slice.y) / 100;
			formattedData.push({
				data: [slice],
				size: 200,
				type: 'pie',
				borderWidth: 5,
				startAngle: start,
				endAngle: end,
				dataLabels: label
			});
			//Start the next slice at the end of this one
			start = end;
		});

		//Don't render the chart if we don't have data to populate it and a place to render it
		if (formattedData && pieChartContainer) {
			Highcharts.chart(pieChartContainer, {
				chart: {
					type: 'pie'
				},
				tooltip: {
					headerFormat: null,
					formatter() {
						// eslint-disable-next-line no-inline-comments
						return /*html*/ `
							<div class="pie-tooltip">
								<div style="color: ${this.point.altColor}">${this.point.name}&emsp;${this.point.y}%</div>
								<div>${this.point.count} ${countType}${this.point.count > 1 ? 's' : ''}</div>
							</div>
						`;
					},
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					style: {
						fontSize: '1.8rem',
						fontWeight: 400,
						padding: 40,
						color: '#333'
					}
				},
				plotOptions: {
					series: {
						dataLabels: {
							enabled: true
						},
						point: {
							events: {
								mouseOver(e) {
									this.options.oldColor = e.target.color;
									this.graphic.attr('style', 'transition: fill .15s ease');
									this.graphic.attr('fill', this.altColor);
								},
								mouseOut() {
									this.graphic.attr('fill', this.options.oldColor);
								}
							}
						}
					}
				},
				series: formattedData
			});
		} else if (target) {
			// eslint-disable-next-line no-console
			console.warn('Could not render pie chart. Either no data was supplied, or no target element could be found.');
		}
	});
};

export default {
	init(data, target) {
		renderPieChart(data, target);
	}
};
